import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { IS_E2E_TEST } from './general';
import constants from 'erb/rendered/rails-constants';
import { APP_ENV } from './constants';

// Only used for e2e tests
const testFbConfig = {
  apiKey: '12345',
  projectId: 'threadableFirebaseEmulator',
};

const config = IS_E2E_TEST ? testFbConfig : constants?.[APP_ENV]?.firebase;

export const firebaseApp = firebase.initializeApp(config);
export const firestore = firebase.firestore(firebaseApp);

if (IS_E2E_TEST) {
  // Emulate Auth: https://firebase.google.com/docs/emulator-suite/connect_auth#web-namespaced-api
  firebase.auth().useEmulator('http://localhost:9099/');
}
