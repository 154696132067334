import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { api } from '.';
import { Genre } from 'web/store/genres/types';
import { useSession } from './session';
import { reactQueryClient } from 'shared/components/Providers/ThreadableQueryClientProvider';
import { useEffect } from 'react';

export const useGenres = () => {
  return useQuery([queryKeys.genres.index], async () => {
    const response = await api.get<Genre[]>('/genres');
    return response.data;
  });
};

export const useGenresForOnboarding = () => {
  return useQuery([queryKeys.genres.onboarding], async () => {
    const response = await api.get<Genre[]>('/genres', { params: { onboarding: true } });
    return response.data;
  });
};

// Load and cache genres after 2 seconds
export const useSetupInitialGenresOnLoad = () => {
  const sessionFunction = useSession();

  const cacheGenres = async () => {
    const [genresResponse, onboardingGenresResponse] = await Promise.all([
      api.get<Genre[]>('/genres'),
      api.get<Genre[]>('/genres', { params: { onboarding: true } }),
    ]);

    reactQueryClient.setQueryData([queryKeys.genres.index], genresResponse.data);
    reactQueryClient.setQueryData([queryKeys.genres.onboarding], onboardingGenresResponse.data);
  };

  useEffect(() => {
    if (sessionFunction.isSuccess) {
      setTimeout(() => {
        cacheGenres();
      }, 2_000);
    }
  }, [sessionFunction.isSuccess]);
};
