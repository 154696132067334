import { showToast } from '../toast';

// This is a helper function to display custom messages for firebase errors
// Technically it will display the message inside any error object, but if
// it's a Fb error witht a custom message configured, it will show that.
const displayFirebaseError = (err: unknown) => {
  // useful for now as we're likely to encounter new errors
  // for a while as this gets set up
  console.log('raw Error', err);

  let msg: string;

  // custom messages for when we don't want to use the raw firebase error message
  const msgMap: Record<string, string> = {
    'auth/user-not-found':
      'User not found. You may have typed your email incorrectly, or may need to create an account.',
    'auth/wrong-password': 'The credentials are invalid',
    'auth/internal-error':
      'There was an issue logging you in. Please make sure your email and password are correct.',
    unknown: 'Something went wrong.',
  };

  // basically check for an error object
  if (
    err &&
    typeof err === 'object' &&
    'code' in err &&
    'message' in err &&
    typeof err.code === 'string' &&
    typeof err.message === 'string'
  ) {
    // if custom error message exists use it
    if (msgMap[err.code]) {
      msg = msgMap[err.code];
      // else use the error message from firebase
    } else {
      // TODO: looks like sometimes the message can be stringified JSON
      // It could be useful to check for jsong strings, ie, "{" and show a default human-readable message
      // if that is the case
      msg = err.message;
    }
  } else {
    msg = msgMap['unknown'];
  }

  showToast({
    message: msg,
    type: 'info',
  });
};

export default displayFirebaseError;
